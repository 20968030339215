var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card p-2"},[_vm._m(0),_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-md-4"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"asd"}},[_vm._v("หัวข้อข่าว")]),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"asd"}},[_vm._v("เนื้อหาย่อ")]),_c('validation-provider',{attrs:{"name":"News Short","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"asd"}},[_vm._v("ลิ้งข่าว")]),_c('validation-provider',{attrs:{"name":"Topic","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.ref_url),callback:function ($$v) {_vm.ref_url=$$v},expression:"ref_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"asd"}},[_vm._v("ลิ้งรูป")]),_c('validation-provider',{attrs:{"name":"Topic","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.img_url),callback:function ($$v) {_vm.img_url=$$v},expression:"img_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.img_url)?_c('img',{attrs:{"src":_vm.img_url,"width":"300","height":"200","frameborder":"0","allowfullscreen":""}}):_vm._e()],1)],1)],1)],1)],1),_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-12"},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" บันทึกข้อมูล ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mb-0"},[_vm._v(" เพิ่มข่าวสารประชาสัมพันธ์ ")])])}]

export { render, staticRenderFns }